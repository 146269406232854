import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import { ACTIONS, MESSAGES } from '../../shared/constants';
import DataTable from '../../shared/data-table';
import {
  getAllProfile,
  deleteSelectedProfile,
  getPositionHomologationEngrana,
  getRequired,
  getAllAirports,
  getProfilesByEmployee,
  updateDataRequired,
  SaveAllRequiredExcel
} from './logic';
//import ProfileTypeDetails from '../../Profile-type/Profile-type-detail';
import RequiredDetail from '../compensation/compensation-required';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import AddCircle from '@mui/icons-material/AddCircle';
import ErrorSnackbar from '../error-snackbar';
import BackDrop from '../../shared/backdropDE';
import { getAllPermissionsByProfile } from '../../shared/data-services/management-profile-service';
import { getUser } from '../../shared/auth-service';
import AlertDialogQuestion from '../alert-dialog-question';

const Compensation = () => {
  const [fecha, setFecha] = useState(null);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [action, setAction] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeePositionId, setEmployeePositionId] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [optionDetails, setOptionDetails] = useState(null);
  const [uniquePositions, setUniquePositions] = useState([]);
  const user = getUser();
  const [loading, setLoading] = useState(true);
  const [airports, setAirports] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(0);
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [selectedCantidad, setSelectedCantidad] = useState(null);
  const [alertQuestionOpen, setAlertQuestionOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [employeePositions, setEmployeePositions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (loading) {
        try {
          const data = await getRequired();
          const employeePositionsData = await getPositionHomologationEngrana();
          setEmployeePositions(employeePositionsData);
          const airportsData = await getAllAirports();
          //console.log(airportsData);
          setSelectedAirport(user.airport.id);
          //console.log('info employee');
          console.log(data);
          setAirports(airportsData);
          const profiles = await getAllProfile();
          setLoading(false);
          setData(data);
          setProfiles(profiles);
        } catch (error) {
          setError(error.message);
          setLoading(false);
          setData([]);
        }
      }
    }
    fetchData();
  });

  useEffect(() => {
    if (data) {
      setUniquePositions(getUniquePositions(data));
    }
  }, [data]);

  const getUniquePositions = (data) => {
    const unique = {};
    data.forEach((item) => {
      if (!unique[item.profile]) {
        unique[item.profile] = item;
      }
    });
    //console.log(unique);
    return Object.values(unique);
  };

  const handleCloseAlert = () => {
    setAlertQuestionOpen(false);
    setIdDelete(null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const handleSave = async (airportId,employeePositionId,mes,anio,cantidad,observation) => {
  //   debugger;
  //   await updateDataRequired(airportId,employeePositionId,mes,anio,cantidad,observation);
  //   setShowModal(false);
  //   setLoading(true);
  //   enqueueSnackbar(MESSAGES.SAVED);
  // };


  const handleSave = async (airportId, employeePositionId, mes, anio, cantidad, observation) => {
    debugger;    
    const data = {
      "base" : airportId,
      "cargo":employeePositionId,
      "anio":anio, 
      "mes":mes, 
      "cantidadRequerida":cantidad, 
      "observacion":observation,
    };
    // Crear un arreglo y agregar el objeto
    const dataArray = [];
    dataArray.push(data);
    // Pasar los datos a la función updateDataRequired
    await updateDataRequired(dataArray);
    setShowModal(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.SAVED);
  };

  const handleDelete = async (cellValues) => {
    //console.log(cellValues);
    await deleteSelectedProfile(idDelete);
    setShowModal(false);
    setAlertQuestionOpen(false);
    setLoading(true);
    enqueueSnackbar(MESSAGES.DELETE);
  };

  const handleNew = async () => {
    setSelectedItem({});
    setId(null);
    setShowModal(true);
    setAction(ACTIONS.NEW);
  };

  const handleActionButton = (e, cellValues, action) => {
    e.stopPropagation();
    setId(cellValues.row.id);
    setAction(action);
  };

  const handleEdit = (e, cellValues) => {
    e.stopPropagation(); 
    //setEmployee(cellValues.row.description)
    //console.log('data de la fila');
    //console.log(cellValues.row);
    setSelectedAirport(cellValues.row.aeropuertoId)
    setEmployeePositionId(cellValues.row.cargoId);
    setSelectedCantidad(cellValues.row.cantidad);
    setSelectedFecha(cellValues.row.fecha);
    fetchOptionDetails(cellValues.row.id); 
  }

  const handleSaveImport = async (
    data
    ) => {
        try {

           var response = await SaveAllRequiredExcel(data);

          var returnImport = response.data;
                
          if(returnImport.length === 1){
            returnImport = [];
          }
          if(returnImport.length > 1) returnImport.splice(0, 1);

          setShowModal(false);
          setLoading(true);
          enqueueSnackbar(MESSAGES.SAVED);
        } catch (error) {
            setError(error.message);
        }

  };

  const fetchOptionDetails = async (id) => {
    try {
      setOptionDetails('');
      setShowModal(true);
      setAction(ACTIONS.Edit);
    } catch (error) {
      setError(error.message);
    } finally {
    }
  };

  const columns = [
    { field: 'id', headerName: 'Código', miwidth: 100, flex: 0.2, hide: true },
    {
      field: 'aeropuertoId',
      headerName: 'AeropuertoId',
      minwidth: 350,
      flex: 1,
      hide: true,
      valueGetter: (params) => `${params.row.aeropuertoId || ''}`,
    },
    {
      field: 'aeropuerto',
      headerName: 'Aeropuerto',
      minwidth: 350,
      flex: 0.3,
      headerAlign: 'center',align: 'center',
      valueGetter: (params) => `${params.row.aeropuerto || ''}`,
    },
    {
      field: 'cargoId',
      headerName: 'CargoId',
      minwidth: 350,
      flex: 1,
      hide: true,
      valueGetter: (params) => `${params.row.cargoId || ''}`,
    },
    {
      field: 'cargo',
      headerName: 'Cargo Engrana',
      minwidth: 350,
      flex: 0.6,
      headerAlign: 'center',
      valueGetter: (params) => `${params.row.cargo || ''}`,
    },
    {
        field: 'fecha',
        headerName: 'Fecha',
        minwidth: 350,
        flex: 0.3,
        headerAlign: 'center',align: 'center',
        valueGetter: (params) => `${params.row.fecha}`,
    },
    {
      field: 'cantidad',
      headerName: 'Cantidad Requerida',
      type: 'number',
      minwidth: 150,
      flex: 0.3,
      align: 'center', headerAlign: 'center',
      valueGetter: (params) => params.row.cantidad,
    },
    {
      field: 'observacion',
      headerName: 'Observación',
      type: 'number',
      minwidth: 150,
      flex: 0.8,
      align: 'center', headerAlign: 'center',
      valueGetter: (params) => params.row.observacion,
    },
  ];

  if (loading) {
    return (
      <BackDrop />
    );
  }

  if (error) {
    return (<ErrorSnackbar message={error} />);
  }

  return (
    <>
      {showModal && <RequiredDetail
        data={optionDetails}
        profiles={profiles}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        airportId={selectedAirport}
        employeePositions = {employeePositions}
        airports={airports}
        action={action}
        handleSaveImport={handleSaveImport}
      />}

      {alertQuestionOpen && <AlertDialogQuestion
        handlePositive={handleDelete}
        handleNegative={handleCloseAlert}
        textButtonPositive={ACTIONS.ACCEPT}
        textButtonNegative={ACTIONS.CANCEL}
        dialogContentText={MESSAGES.CONFIRM_DELETE}
        dialogTitle={""}
        open={alertQuestionOpen}
        elemento={""}
      />}

      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
            Gestión de <span style={{ color: '#00b0ef' }}>Requeridos</span>
          </Typography>
          {/* <Typography variant="h5" gutterBottom>
                {`${TITLES.Profile_TYPE}`}
              </Typography> */}
        </Grid>
        <Box sx={{ m: 4 }}>
          <Grid item xs={2}>
            <Button
              endIcon={<AddCircle />}
              variant="contained"
              onClick={() => { handleNew() }} autoFocus>{ACTIONS.NEW}</Button>
          </Grid>
        </Box>
      </Grid>
      <DataTable columns={columns} rows={data} 
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', // Aplicar negrita a los títulos de las columnas
          }
        }}/>
    </>
  );
};

export default Compensation;
