import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Autocomplete,
  CircularProgress,
  Chip,
  Tab,
  Tabs,
  LinearProgress
} from "@mui/material";
import {
  getemployeesByAirport,
  getallemployeesByAirport,
  getallemployeesByAirportForEdit
} from '../logic';
import { DataGrid } from '@mui/x-data-grid';
import { Spanish } from '../../../shared/grid-translations';
import Button from '@mui/material/Button';
import Delete from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { ACTIONS } from '../../../shared/constants';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSnackbar } from 'notistack';
import ExportExcel from '../../../shared/exportExcel';
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import InfoIcon from '@mui/icons-material/Info';
import { MuiFileInput } from 'mui-file-input';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import * as XLSX from 'xlsx';

const RequiredDetail = ({
  handleSaveImport,
  fecha,
  handleClose,
  handleSave,
  employeePositions,
  airports,
  airportId,
  action
}) => {
  const [selectedAirport, setSelectedAirport] = useState(airportId);
  const isDataReadOnly = action !== ACTIONS.NEW;
  const [validationErrors, setValidationErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const allEmployeePositionOption = { id: -1, code: "TODOS", name: "TODOS" };
  const employeePositionWithOptions = [...employeePositions];
  const [selectedEmployeePositionId, setSelectedEmployeePosition] = useState(null);
  //const [date, setDate] = useState(fecha || dayjs());
  const [code, setCode] = useState('');
  const [observation, setObservation] = useState('');
  const [showProgressLoading, setShowProgressLoading] = React.useState(false);
  const [errorsValidation, setErrorsValidation] = React.useState(0);
  const [sucessValidation, setsuccessValidation] = React.useState(0);
  const [yearMonth, setYearMonth] = React.useState([]);
  const [yearMonthSelected, setYearMonthSelected] = React.useState(((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString());
  const [date, setDate] = React.useState((((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).substring(0, 4) + '-' + (((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).substring(4, (((new Date()).getFullYear().toString()) + ((new Date()).getMonth() + 1).toString()).length) + '-01');
  const [file, setFile] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [dataFile, setDataFile] = React.useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  function getTextFromTimeXLS(data) {
    //SACAR LA HORA CUANDO VIENE EN DECIMALES
    var HoraInt = data;
    var Result = (HoraInt * 24).toFixed(2);
    var P1 = Result.toString().split(".");
    var ResultHora = P1[0];
    var ResultMinutos = Math.round((parseInt(P1[1]) * 60) / 100);

    if (ResultHora < 10) {
      ResultHora = "0" + ResultHora;
    }

    if (ResultMinutos < 10) {
      ResultMinutos = "0" + ResultMinutos;
    }

    return (ResultHora + ":" + ResultMinutos);
  }

  const handleCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && parseInt(value, 10) >= 0) {
      setCode(value);
    } else {
      setCode('');
      enqueueSnackbar("Por favor, ingrese un número entero mayor o igual a 0", { variant: "error" });
    }
  };
  const handleSelectYearMonth = async (e) => {
    e.stopPropagation();
    setYearMonthSelected(e.target.value);

    var yearMonth = e.target.value.toString().trim();
    var datee = yearMonth.substring(0, 4) + '-' + (yearMonth.substring(4, yearMonth.length).length === 1 ? '0' + yearMonth.substring(4, yearMonth.length) : yearMonth.substring(4, yearMonth.length)) + '-01';

    setDate(datee);

    setFile(null);
    setData([]);
    setErrorsValidation(0);
    setsuccessValidation(0);

  };
  const handleObservationChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500) {
      setObservation(value);
    }
    else {
      enqueueSnackbar("El número de carecteres maximo es 500", { variant: "error" });
    }
  };
  const handleChange= async (e) => {
    console.log(e);
    setFile(e);
    setShowProgressLoading(true);
    let rows = [];
    const isNumeric = n => !isNaN(n);

    if (e) {
      const reader = new FileReader();
      const FileName = e.name;

      reader.onload = async (e) => {
        const d = e.target.result;

        //Convertir archivo a BASE64
        var binary = '';
        var bytes = new Uint8Array(d);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }

        var FileBase64 = window.btoa(binary);

        const dataFile = {
          FileName: FileName,
          FileData: FileBase64
        };
        setDataFile(dataFile);

        const workbook = XLSX.read(d, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        rows.push(json);

        let data = [];
        var errors = 0;
        var success = 0;
        var _YearMonth = yearMonthSelected.substring(0, 4) + '-' + yearMonthSelected.substring(4, yearMonthSelected.length);

        await rows[0].forEach(element => {

          if (element.BASE === '' || element.BASE === undefined
            || element.CARGO_ENGRANA === '' || element.CARGO_ENGRANA === undefined
            || element.CANTIDADREQUERIDA === '' || element.CANTIDADREQUERIDA === undefined
            || element.ANIO === '' || element.ANIO === undefined
            || element.MES === '' || element.MES === undefined
            || element.OBSERVACION === undefined

          ) {
            errors++;
            return;
          } else {
            success++;
          }
          let Base = element.BASE === undefined ? element.base === undefined ? element.Base : element.base : element.BASE;
          let Cargo = element.CARGO_ENGRANA === undefined ? element.cargo_engrana === undefined ? element.CARGO_ENGRANA : element.cargo_engrana : element.CARGO_ENGRANA;
          let CantidadRequerida = element.CANTIDADREQUERIDA === undefined ? element.cantidadrequerida === undefined ? element.CantidadRequerida : element.cantidadrequerida : element.CANTIDADREQUERIDA;
          let Observacion = element.OBSERVACION === undefined ? element.observacion === undefined ? element.Observacion : element.observacion : element.OBSERVACION;
          let ANIO = element.ANIO === undefined ? element.anio === undefined ? element.ANIO : element.anio : element.ANIO;
          let MES = element.MES === undefined ? element.mes === undefined ? element.MES : element.mes : element.MES;

          var required = {
            base: Base.toString().trim(),
            cargo: Cargo.toString().trim(),
            cantidadRequerida: CantidadRequerida.toString().trim(),
            observacion: Observacion.toString().trim(),
            ANIO: ANIO.toString().trim(),
            MES: MES.toString().trim(),
          };

          data.push(required);
        });

        setData(data);
        setErrorsValidation(errors);
        setsuccessValidation(success);
        setShowProgressLoading(false);
      }
      reader.readAsArrayBuffer(e);
    }
  };

 
  const onSave = () => {
    let errors = {};
    //console.log(selectedEmployeePositionId);
    if (selectedAirport === '' || selectedAirport === null || selectedAirport === undefined || selectedEmployeePositionId === '' ||
      selectedEmployeePositionId === null || selectedEmployeePositionId === undefined || date === '' || date === null || date === undefined || code === '' || code === null ||
      code === undefined) {
      errors.name = "Debe ingresar todos los datos"; // Esto es solo un ejemplo, idealmente usarías algo como Material-UI Snackbars o un estado para mostrar el error.
      setValidationErrors(errors);
      enqueueSnackbar(errors.name, { variant: "error" });
      return Object.keys(errors).length === 0;
    }

    const month = dayjs(date).month() + 1; // Meses están indexados desde 0
    const year = dayjs(date).year();

    // Llamar a handleSave con el nuevo objeto selectedCodesSend
    handleSave(selectedAirport, selectedEmployeePositionId, month, year, code, observation);

  };
  const PlantillaExcel = [
    {
      "BASE": "",
      "CARGO_ENGRANA": "",
      "ANIO": "",
      "MES": "",
      "CANTIDADREQUERIDA": "",
      "OBSERVACION": ""
    }
  ];
  const HeadersPlantilla = [
      "BASE",
      "CARGO_ENGRANA",
      "ANIO",
      "MES",
      "CANTIDADREQUERIDA",
      "OBSERVACION"
  ];


  return (
    <div style={{ overflow: 'hidden' }}>
      <Dialog
        maxWidth="sm"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ overflow: 'hidden' }}
      >
        <DialogTitle id="alert-dialog-title">Detalle Requerido</DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 3 }}>
            <i>Los campos marcados con <strong>*</strong> son obligatorios</i>
          </Typography>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
            <Tab label="Importar Archivo" />
            <Tab label="Formulario" />
          </Tabs>
          {tabIndex === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="body2" style={{ color: '#1a3072', fontSize: '12px' }} sx={{ flexGrow: 1 }}>
                    <InfoIcon sx={{ fontSize: 'medium' }} /> Seleccione el archivo de Excel, luego de clic en el botón de Continuar para guardar los registros.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <MuiFileInput
                    value={file}
                    size="small"
                    inputProps={{ accept: '.xlsx' }}
                    onChange={handleChange}
                    placeholder='Selecciona el archivo de Excel'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <ExportExcel excelData={PlantillaExcel} excelHeaders={HeadersPlantilla} fileName={"Plantilla de importación de requeridos"} />
              </Grid>
              {errorsValidation > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }}>
                    <Chip icon={<FmdBadIcon />} label={`Hay ${errorsValidation} ${errorsValidation === 1 ? "error" : "errores"} en la información. Por favor corrija y vuelva a intentarlo`} color="error" variant="outlined" />
                  </Box>
                </Grid>
              )}
              {errorsValidation === 0 && sucessValidation > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }}>
                    <Chip icon={<WhereToVoteIcon />} label={`Se detectaron ${sucessValidation} registros correctamente.`} color="success" variant="outlined" />
                  </Box>
                </Grid>
              )}
              {showProgressLoading && (
                <Grid item xs={12}>
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <LinearProgress />
                    Estamos procesando la información del archivo, esto puede tardar unos minutos.
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          {tabIndex === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="demo-simple-select-label">Base *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Base *"
                    size='small'
                    value={selectedAirport}
                    onChange={(e) => setSelectedAirport(e.target.value)}
                  >
                    {airports.map(item => (
                      <MenuItem key={item.id} value={item.code}>{item.code}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <Autocomplete
                    disablePortal
                    noOptionsText={'Sin resultados'}
                    id="employee-position-autocomplete"
                    size='small'
                    options={employeePositionWithOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setSelectedEmployeePosition(newValue ? newValue.name : null);
                    }}
                    renderInput={(params) => <TextField {...params} label="Cargo Engrana *" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es-ES'}>
                  <FormControl fullWidth margin="dense">
                    <DatePicker
                      label="Fecha *"
                      views={['year', 'month']}
                      inputFormat="MM/YYYY"
                      value={date}
                      minDate={moment().toDate()}
                      onChange={(e) => setDate(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Cantidad Requerida *"
                    onChange={handleCodeChange}
                    value={code}
                    fullWidth
                    error={code !== '' && (parseInt(code, 10) < 0 || !/^\d+$/.test(code))}
                    helperText={code !== '' && (parseInt(code, 10) < 0 || !/^\d+$/.test(code)) ? "Debe ingresar un número entero mayor o igual a 0" : ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    label="Observación"
                    onChange={handleObservationChange}
                    value={observation}
                    fullWidth
                    error={observation.length > 500}
                    helperText={observation.length > 500 ? "El texto no debe exceder los 500 caracteres" : ''}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 3 }}>
          {tabIndex === 0 && (
            <>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={showProgressLoading || errorsValidation > 0 || sucessValidation === 0}
                  endIcon={<CheckIcon />}
                  onClick={() => { setShowProgressLoading(true); handleSaveImport(data, dataFile, date) }}
                >
                  {ACTIONS.CONTINUE}
                </Button>
              </Box>
              <Box>
                <Button
                  endIcon={<CancelIcon />}
                  variant="outlined"
                  disabled={showProgressLoading}
                  onClick={handleClose}
                  autoFocus
                >
                  {ACTIONS.CANCEL}
                </Button>
              </Box>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSave}
                  endIcon={<SaveIcon />}
                >
                  {ACTIONS.SAVE}
                </Button>
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  endIcon={<CancelIcon />}
                  variant="outlined"
                  onClick={handleClose}
                >
                  {ACTIONS.CANCEL}
                </Button>
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RequiredDetail;
