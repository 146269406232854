import { getProfile, saveProfile, deleteProfile, getProfileById, savePermissions, getPermissions, getAllPermissionsByProfile } from '../../shared/data-services/management-profile-service';
import { getEmployeesWithProfiles, updateProfilesByEmployee, getAllProfilesByEmployee, deleteEmployeeByProfile, getAllEmployeesByAirport, getAllEmployeesByAirportForEdit } from '../../shared/data-services/management-employee-service';
import { getAirports } from '../../shared/data-services/airport-service';
import { getEmployeesByAirport, getUserFaceFeature, saveNewUser, updateUser } from '../../shared/data-services/talmaid-service';
import { getAllPositionHomologationEngrana } from '../../shared/data-services/positionHomologation-service';
import { updateRequired, getAllInfoRequired, SaveRequiredExcel } from '../../shared/data-services/required-service';
import { ModelTrainingOutlined } from '@mui/icons-material';




export async function getPositionHomologationEngrana (){
  
    const data = await getAllPositionHomologationEngrana();
    return data;
};

export async function updateDataRequired (data){
    await updateRequired(data);
    return;
  };

export async function getAllProfile (){
  const data = await getProfile();
  return data || [ ];
};

export async function getemployeesByAirport(AirportId){
  const data = await getEmployeesByAirport(AirportId);
  return data;
}

export async function getallemployeesByAirport(AirportId){
  const data = await getAllEmployeesByAirport(AirportId);
  return data;
}

export async function getallemployeesByAirportForEdit(AirportId){
  const data = await getAllEmployeesByAirportForEdit(AirportId);
  return data;
}

export async function SaveAllRequiredExcel (model){
  const data = await SaveRequiredExcel(model);
  return data;
};

export async function getAllAirports (){
  const data = await getAirports();
  return data;
};

export async function getEmployees (){
  const data = await getEmployeesWithProfiles();
  return data || [ ];
};

export async function getRequired (){
  const data = await getAllInfoRequired();
  return data || [ ];
};

export async function updateAllProfilesByEmployee (profiles){
  await updateProfilesByEmployee(profiles);
  return;
};

export async function getProfilesByEmployee (employeeId){
  const data = await getAllProfilesByEmployee(employeeId);
  return data || [ ];
};

export async function getSelectedProfile (id){
  const data = await getProfileById(id);
  return data;
};

export async function saveSelectedProfile (id, description){
  const Profile = {
    id, 
    description
  }

  const data = await saveProfile(Profile);
  return data;
};


export async function getPermissionByProfile (profileId){
  const data = await getAllPermissionsByProfile(profileId);
  return data || [ ];
};

export async function getAllPermissions(){
  const data = await getPermissions();
  return data || [];
};


export async function saveSelectedPermissions (permissions, profileId, profileName, action){
  // const Permission = {
  //   code, 
  //   description: description
  // }
  if(action === 'CREAR'){
    profileId = null
  }
  const data = await savePermissions(permissions, profileId, profileName);
  return data;
};

export async function deleteSelectedProfile (id){
  const data = await deleteEmployeeByProfile(id);
  return data;
};