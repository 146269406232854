import { callAPI } from '../network';


export async function updateRequired (list){

        //console.log(profiles);
   //  const params = {
   //      airportId: airportId,
   //      employeePositionId: employeePositionId,
   //      mes: mes,
   //      anio: anio,
   //      cantidad: cantidad,
   //      observacion: observacion
   //  };

    
    const data = await callAPI(
                    list, 
                        'Required/SaveDataRequired', 'post');
    return data || [ ];
 }


 export async function getAllInfoRequired (){
  
    const data = await callAPI(
                         null, 
                         'Required/GetAllInfoRequired', 'get');
    return data || [ ];
 }


 export async function SaveRequiredExcel (model){
  
    const data = await callAPI(
                         model, 
                         'Required/SaveDataRequired', 'post');
    return data || [ ];
 }